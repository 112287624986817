import React, { useEffect } from "react";

const TicketRedirect: React.FC = () => {
  useEffect(() => {
    window.location.href =
      "https://www.iabilet.ro/bilete-metal-gates-festival-2024-89803/";
  }, []);

  return <div>Redirecting...</div>;
};

export default TicketRedirect;
export {};
